import {
  IWpState,
  IWpDetailedPost,
  IWpAcfBlock,
  IWpCTA,
} from '@interfaces/WpTypes'
import { isObject } from 'lodash-es'

const mapUnderscoreKeysToCamelCase = (block): IWpAcfBlock => {
  const result = Object.keys(block).reduce((acc, blockKey) => {
    const ccKey = blockKey.replace(/_./g, (_) => _[1].toUpperCase())
    acc[ccKey] = isObject(block[blockKey])
      ? mapUnderscoreKeysToCamelCase(block[blockKey])
      : block[blockKey]
    return acc
  }, {}) as IWpAcfBlock
  return result
}

export default {
  updateBanner: (state, params) => {
    state.currentBanner = {
      title: params.title || '',
      description: params.description || ''
    }
    console.log("store updated banner:", state.currentBanner)
  },
  updateBusy: (state, busy) => {
    state.isBusy = busy
  },
  updatePageAcfBlocks: (state: IWpState, blocks: any[]) => {
    const mappedBlocks = blocks.map(mapUnderscoreKeysToCamelCase)
    state.acf.blocks = mappedBlocks
  },

  updateGameAcfBlocks: (state: IWpState, blocks: any) => {
    let mappedBlocks: IWpAcfBlock[] = []
    mappedBlocks.push(mapUnderscoreKeysToCamelCase(blocks))
    state.gameData.blocks = mappedBlocks
  },

  updateFeaturedMedia: (state: IWpState, featuredMedia: any) => {
    state.featuredMedia = featuredMedia
  },

  updateJobAcfBlocks: (state: IWpState, blocks: any) => {
    let mappedBlocks: IWpAcfBlock[] = []
    mappedBlocks.push(mapUnderscoreKeysToCamelCase(blocks))
    state.jobsData.blocks = mappedBlocks
  },

  updatePosts: (state, posts) => {
    state.posts = posts
  },

  updatePostThumbnail: (state, { postId, thumbnail }) => {
    state.posts = state.posts.map((post, index) => {
      if ((post.id || post.ID) === postId) {
        post.thumbnail = thumbnail
        return { ...post, thumbnail: thumbnail }
      }
      return post
    })
  },

  updatePageContent: (state: IWpState, content: string) => {
    state.pageContent = content
  },
  updatePageTitle: (state: IWpState, title: string) => {
    state.pageTitle = title
  },
  updatePageHead: (state: IWpState, head: Record<string, string>) => {
    state.pageHead = head
  },
  updateAcfData: (state: IWpState, acf: any) => {
    state.acfData = acf
  },
  updateAuthor: (state, author) => {
    state.author = author
  },
  appendCategory: (state, category) => {
    if (category.count === 0) {
      return
    }

    state.categories[category.id] = category
    state.categoriesSlugs[category.slug] = category.id
  },
  selectCategoryBySlug: (state, slugCategory) => {
    if (!slugCategory || !state.categoriesSlugs[slugCategory]) {
      state.selectedCategory = null
      return
    }

    state.selectedCategory = state.categoriesSlugs[slugCategory]
  },
  selectPost: (state, post: IWpDetailedPost) => {
    state.selectedPost = post
  },
  updateDiagramsListData: (state: IWpState, diagrams: any) => {
    if (state.diagrams) {
      state.diagrams.diagramsList = diagrams
    }
  },
  setBreadcrumbs(state, breadcrumbs: string) {
    state.breadcrumbs = breadcrumbs
  },
  setPageNumber(state, pageNumber: number) {
    state.pagination.currentPage = pageNumber
  },
  setPaginationTotal(state, total: number) {
    state.pagination.total = total
  },
  setSpotlights(state, spotlights: any) {
    state.spotLights = spotlights
  },
  setTwitterCode(state, code: string) {
    if (!state.acf || !state.acf.blocks) {
      return
    }

    const twitterBlockIndex = state.acf.blocks.findIndex(
      (block) => block.acfFcLayout === 'twitter'
    )

    if (!state.acf.blocks[twitterBlockIndex]) {
      return
    }

    state.acf.blocks[twitterBlockIndex].block.code = code
    state.acf = JSON.parse(JSON.stringify(state.acf));
  },
  setTwitterData(state, data: any[]) {
    if (!state.acf || !state.acf.blocks) {
      return
    }

    const twitterBlockIndex = state.acf.blocks.findIndex(
      (block) => block.acfFcLayout === 'twitter'
    )

    if (!state.acf.blocks[twitterBlockIndex]) {
      return
    }

    state.acf.blocks[twitterBlockIndex].block.data = data
    state.acf = JSON.parse(JSON.stringify(state.acf));
  },
  setDisclaimer(state, value: string) {
    state.disclaimer = value
  },
  setCta(state, value: IWpCTA) {
    state.cta = value
  },
}
