
import Vue from 'vue'
import MobileCommunitySubMenu from '../header/MobileCommunitySubMenu.vue'
import { getProfileUrl } from '~/domain/helpers'
export default Vue.extend({
  name: 'MobileMenu',
  components: {
    MobileCommunitySubMenu,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItemId: '',
    }
  },
  computed: {
    commonMenu() {
      return this.$store.state.common.menu
    },
    userIsAuthenticated() {
      return this.$store.getters.userIsAuthenticated
    },
    fetchUserComplete() {
      return this.$store.getters.fetchUserComplete
    },
    registerUrl() {
      return `${this.$config.apiBaseURL}/register`
    },
    user() {
      return this.$store.getters.authenticatedUser
    },
    profileUrl() {
      return getProfileUrl(this.$store.getters.authenticatedUser)
    },
  },

  methods: {
    switchDrawer() {
      this.$emit('switchDrawer')
    },
    mobileMenuItemPressed: function (value: string) {
      if (value === this.menuItemId) {
        this.menuItemId = ''
      } else this.menuItemId = value
    },
    login: function () {
      this.$auth.login()
    },
    logout() {
      this.$auth.logout()
    },
    logoPressed() {
      this.$router.push('/')
    },
    menuHasChildren(menuItem) {
      return (
        (menuItem && menuItem?.children?.length) ||
        menuItem?.name === 'Community'
      )
    },
    getAbsolutePath(slug: string) {
      return slug.startsWith('/') ? slug : `/${slug}`
    },
    isActiveRoute(currentPageSlug, menuItem) {
      if (!currentPageSlug || !menuItem) return false

      const cleanCurrentSlug =
        currentPageSlug.replace(/^\/|\/$/g, '').split('/')[0] || ''
      const itemSlug = menuItem.slug?.replace(/^\/|\/$/g, '') || ''
      if (cleanCurrentSlug === itemSlug) {
        return true
      }

      const checkNestedChildren = (children) => {
        if (!children) return false

        return children.some((child) => {
          const childSlug = child.slug?.replace(/^\/|\/$/g, '') || ''
          if (cleanCurrentSlug === childSlug) {
            return true
          }

          if (child.children?.length) {
            return checkNestedChildren(child.children)
          }

          return false
        })
      }

      return checkNestedChildren(menuItem.children)
    },
    isActiveRouteSubitem(currentPageSlug, menuItem) {
      if (!currentPageSlug || !menuItem) return false
      const cleanCurrentSlug = currentPageSlug.replace(/^\/|\/$/g, '') || ''
      const itemSlug = menuItem.slug?.replace(/^\/|\/$/g, '') || ''

      if (menuItem.children?.length) {
        return false
      }
      return cleanCurrentSlug === itemSlug
    },
  },
})
