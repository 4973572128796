
import Vue from 'vue'

export type CommunitySubMenu = {
  icon: string
  title: string
  key: string
}

export default Vue.extend({
  name: 'CommunitySubMenu',
  data() {
    return {
      menuItems: [
        {
          icon: '$commDiscordIcon',
          title: 'Discord',
          key: '__discord',
        },
        {
          icon: '$redditSocialIcon',
          title: 'Reddit',
          key: '__reddit',
        },
        {
          icon: '$commTelegramIcon',
          title: 'Telegram',
          key: '__telegram',
        },
        {
          icon: '$twitterSocialIcon',
          title: 'Twitter',
          key: '__twitter',
        },
        {
          icon: '$commYoutubeIcon',
          title: 'Youtube',
          key: '__youtube',
        },
      ],
    }
  },
  methods: {
    communityItemPressed(page: string) {
      this.$router.push(`/community/${page}`)
    },
    menuItemLink(menuItem: CommunitySubMenu) {
      if (process.browser)
        switch (menuItem.title) {
          case 'Youtube':
            return 'https://www.youtube.com/channel/UCnui50w5BC_P7pfrF0XwoKg'
          case 'Twitter':
            return 'https://www.x.com/machinationsio'
          case 'Reddit':
            return 'https://www.reddit.com/r/Machinations/'
          case 'Telegram':
            return 'https://t.me/machinationsio'
          default:
            return 'https://discord.gg/EqKqWAJ'
        }
    },
    isActiveRoute(type: string) {
      if (type === 'explore') {
        return this.$route.path.endsWith('/community/explore')
      }
      return this.$route.query.filter === type
    },
  },
})
