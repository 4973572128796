
import Vue from 'vue'
import CommunitySubMenu from './CommunitySubMenu.vue'
import AuthHeader from './auth/AuthHeader.vue'
import { SearchCategory } from '~/domain/common/types'
import { SEARCH_CATEGORIES } from '@/domain/constants/SearchConst'
import AppActions from '~/domain/constants/AppActions'
import { decodeHTMLEntities } from '~/domain/helpers'

interface SearchResult {
  category: string
  title: string
  id: string
  link: string
}

export default Vue.extend({
  name: 'HeaderNavBar',
  components: {
    CommunitySubMenu,
    AuthHeader,
  },
  data() {
    return {
      visibleId: undefined as number | undefined,
      isSearchBarToggled: false,
      searchTerm: '',
      searchPlaceholder: this.$t('searchSomething', {
        target: 'Machinations',
      }) as string,
      searchResultsSuggestions: [] as SearchResult[],
      showResultsSuggestions: false,
      debounce: null as any,
      searchCategories: SEARCH_CATEGORIES as Record<string, SearchCategory>,
      shouldSearchOverlap: false,
      showSuggestionsLoadingSpinner: false,
      searchCompleted: false,
      isElementVisible: false,
    }
  },
  computed: {
    commonMenu() {
      return this.$store.state.common.menu
    },
    formSubmitError() {
      return this.$store.state.common.formSubmitError
    },
    resultsSuggestionsVisible() {
      return (
        this.searchResultsSuggestions.length > 0 && this.showResultsSuggestions
      )
    },
    showSearch() {
      this.checkVisibility()
      return this.isElementVisible
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.checkVisibility)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.checkVisibility)
  },
  methods: {
    handleClickOutside(event) {
      const searchBar = this.$refs.searchBar as HTMLElement

      if (searchBar && !searchBar.contains(event.target)) {
        this.showResultsSuggestions = false

        if (this.searchTerm.length === 0) {
          this.isSearchBarToggled = false
          this.shouldSearchOverlap = false
        }
      }
    },
    burgerMenuPressed() {
      this.$emit('burgerPressed')
    },
    showSubMenu(id) {
      this.visibleId = id
    },
    handleSearchClose() {
      this.isSearchBarToggled = false
      this.showResultsSuggestions = false
      clearTimeout(this.debounce)
      this.handleSearchClear()
    },
    handleSearch(searchTerm = '', category = '') {
      searchTerm = (searchTerm || this.searchTerm).trim()

      if (this.$route.path === '/search-results') {
        const searchBarInput = document.getElementById(
          'search-results-input'
        ) as HTMLElement

        if (searchBarInput) {
          searchBarInput.focus()
        }
      } else if (!this.isSearchBarToggled) {
        this.updateSearchWidth()

        this.isSearchBarToggled = true

        this.$nextTick(() => {
          const searchInput = this.$refs?.searchInput as HTMLElement
          searchInput && searchInput.focus()
        })
      } else if (searchTerm.length > 0) {
        this.$store.dispatch(AppActions.TRACK_SEGMENT_EVENT, {
          context: this,
          params: {
            name: 'Global Search - Search',
            category: 'Global Search',
            action: 'Search',
            label: searchTerm,
            misc: [
              { type: 'location', value: 'header' },
              { type: 'page', value: this.$route.path },
              { type: 'search term', value: searchTerm },
            ],
          },
        })

        this.showResultsSuggestions = false
        this.isSearchBarToggled = false
        clearTimeout(this.debounce)

        let priorityQueryParam = ''

        if (category.length > 0) {
          if (['designers', 'diagrams'].includes(category)) {
            priorityQueryParam = '&prio=community'
          } else {
            priorityQueryParam = `&prio=${category}`
          }
        } else {
          const isDocsPage = this.$route.path.includes('/docs')
          const isCommunityPage = this.$route.path.includes('/community')

          if (isDocsPage) {
            priorityQueryParam = '&prio=documentation'
          } else if (isCommunityPage) {
            priorityQueryParam = '&prio=community'
          }
        }

        this.$router.push(
          `/search-results?q=${encodeURIComponent(
            decodeHTMLEntities(searchTerm)
          )}${priorityQueryParam}`
        )
      }
    },
    updateSearchWidth() {
      const searchBarWidth = (this.$refs.searchBar as HTMLElement)?.clientWidth
      const menuWidth = (this.$refs.menu as HTMLElement)?.clientWidth

      this.shouldSearchOverlap = searchBarWidth < 256 || menuWidth === 0
    },
    handleWindowResize() {
      const logoWidth = (this.$refs.logo as HTMLElement)?.clientWidth
      const menuWidth = (this.$refs.menu as HTMLElement)?.clientWidth
      const authMenuWidth = (this.$refs.authMenu as HTMLElement)?.clientWidth

      const equalizer = this.$refs.equalizer as HTMLElement
      const searchBar = this.$refs.searchBar as HTMLElement

      equalizer.style.width = `${
        (window.innerWidth - menuWidth) / 2 - logoWidth
      }px`
      searchBar.style.width = `${
        (window.innerWidth - menuWidth) / 2 - authMenuWidth
      }px`

      this.updateSearchWidth()
    },
    async handleInput() {
      this.searchCompleted = false
      this.searchResultsSuggestions = []
      clearTimeout(this.debounce)

      if (this.searchTerm.trim().length > 2) {
        this.debounce = setTimeout(async () => {
          this.showSuggestionsLoadingSpinner = true
          this.showResultsSuggestions = true

          const searchResultsSuggestions = await this.getSearchSuggestions()

          this.searchResultsSuggestions = this.getFilteredSearchSuggestions(
            searchResultsSuggestions
          )
          this.showSuggestionsLoadingSpinner = false
          this.searchCompleted = true
        }, 600)
      }
    },
    async getSearchSuggestions(): Promise<SearchResult[]> {
      let searchSuggestions = [] as SearchResult[]

      if (this.searchTerm.trim().length > 0) {
        searchSuggestions = (await this.$store.dispatch(
          'common/getSearchSuggestions',
          {
            context: this,
            params: {
              term: this.searchTerm.trim(),
              count: 7,
            },
          }
        )) as SearchResult[]
      }

      return searchSuggestions
    },
    getFilteredSearchSuggestions(searchSuggestions: SearchResult[]) {
      let maxSuggestionsLeft = 7
      const sortedSuggestions: SearchResult[] = []
      const categories: { [key: string]: SearchResult[] } = {}
      const categoriesPriority = [
        {
          name: 'documentation',
          hasPriority: this.$route.path.includes('/docs'),
        },
        {
          name: 'diagrams',
          hasPriority:
            this.$route.path.includes('/community') &&
            this.$route.query?.filter !== 'designers',
        },
        {
          name: 'designers',
          hasPriority:
            this.$route.path.includes('/community') &&
            this.$route.query?.filter !== 'diagrams',
        },
      ]

      // Separate the suggestions into categories
      searchSuggestions.forEach((suggestion) => {
        if (!categories[suggestion.category]) {
          categories[suggestion.category] = []
        }
        categories[suggestion.category].push(suggestion)
      })

      // Define prioritized categories
      const prioritizedCategories: string[] = categoriesPriority
        .filter(
          (category) =>
            category.hasPriority &&
            categories[category.name] &&
            categories[category.name].length > 0
        )
        .map((category) => category.name)

      // Add one suggestion from each category if available
      for (const category in categories) {
        if (
          categories.hasOwnProperty(category) &&
          categories[category].length > 0
        ) {
          sortedSuggestions.push(categories[category].pop() as SearchResult)
          maxSuggestionsLeft--
        }
      }

      // Add suggestions from the prioritized categories (one or more)
      if (prioritizedCategories.length > 0) {
        let suggestionsAdded = true

        while (maxSuggestionsLeft > 0 && suggestionsAdded) {
          suggestionsAdded = false

          for (
            let i = 0;
            i < prioritizedCategories.length && maxSuggestionsLeft > 0;
            i++
          ) {
            const prioritizedCategory = prioritizedCategories[i]
            const prioritizedCategorySuggestions =
              categories[prioritizedCategory]

            if (
              prioritizedCategorySuggestions &&
              prioritizedCategorySuggestions.length > 0
            ) {
              sortedSuggestions.push(prioritizedCategorySuggestions.pop()!)
              maxSuggestionsLeft--
              suggestionsAdded = true
            }
          }
        }
      }

      // Add suggestions from the rest of the categories
      if (maxSuggestionsLeft > 0) {
        let suggestionsAdded = true

        while (maxSuggestionsLeft > 0 && suggestionsAdded) {
          suggestionsAdded = false

          for (
            let i = 0;
            i < Object.keys(categories).length && maxSuggestionsLeft > 0;
            i++
          ) {
            const categorySuggestions = Object.values(categories)[i]

            if (categorySuggestions && categorySuggestions.length > 0) {
              sortedSuggestions.push(categorySuggestions.pop()!)
              maxSuggestionsLeft--
              suggestionsAdded = true
            }
          }
        }
      }

      // Sort the suggestions and prioritize them
      sortedSuggestions.sort((a, b) => {
        if (prioritizedCategories.length > 0) {
          if (
            prioritizedCategories.includes(a.category) &&
            !prioritizedCategories.includes(b.category)
          ) {
            return -1
          }
          if (
            !prioritizedCategories.includes(a.category) &&
            prioritizedCategories.includes(b.category)
          ) {
            return 1
          }
        }

        if (a.category < b.category) {
          return -1
        }
        if (a.category > b.category) {
          return 1
        }

        return 0
      })

      return sortedSuggestions
    },
    handleSearchFocus() {
      this.showResultsSuggestions = true
    },
    handleSearchClear() {
      if (this.searchTerm.length === 0) {
        this.isSearchBarToggled = false
      } else {
        this.searchTerm = ''
        this.searchResultsSuggestions = []
      }
    },
    logoPressed() {
      this.$router.push('/')
    },
    menuHasChildren(menuItem) {
      return (
        (menuItem && menuItem?.children?.length) ||
        menuItem?.name === 'Community'
      )
    },
    checkVisibility() {
      if (typeof window === 'undefined') {
        return false
      }

      const input = window.document.querySelector(
        '#content-search-results-input' // this ID should be applied to all search bars inside the page content
      ) as HTMLElement

      if (input) {
        // 80px is the height of the header
        if (input.getBoundingClientRect().bottom < 80) {
          this.isElementVisible = true
        } else {
          this.isElementVisible = false
        }
      } else {
        this.isElementVisible = true
      }
    },
    isActiveRoute(currentPageSlug, menuItem) {
      if (!currentPageSlug || !menuItem) return false

      const cleanCurrentSlug =
        currentPageSlug.replace(/^\/|\/$/g, '').split('/')[0] || ''
      const itemSlug = menuItem.slug?.replace(/^\/|\/$/g, '') || ''
      if (cleanCurrentSlug === itemSlug) {
        return true
      }

      const checkNestedChildren = (children) => {
        if (!children) return false

        return children.some((child) => {
          const childSlug = child.slug?.replace(/^\/|\/$/g, '') || ''
          if (cleanCurrentSlug === childSlug) {
            return true
          }

          if (child.children?.length) {
            return checkNestedChildren(child.children)
          }

          return false
        })
      }

      return checkNestedChildren(menuItem.children)
    },
  },
})
