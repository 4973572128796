
import Vue from 'vue'

export type MobileCommunitySubMenu = {
  title: string
  key: string
  url?: string
  slug?: string
  icon?: string
}
export default Vue.extend({
  name: 'MobileCommunitySubMenu',
  props: {
    menuItem: {
      required: true,
      type: Object,
    },
    menuItemId: {
      required: true,
      type: [Number, String],
    },
  },
  data() {
    return {
      subMenuItems: [
        {
          title: 'Discord',
          key: '__discord',
          url: 'https://discord.gg/EqKqWAJ',
          icon: '$commDiscordIcon',
        },
        {
          title: 'Reddit',
          key: '__reddit',
          url: 'https://www.reddit.com/r/Machinations/',
          icon: '$redditSocialIcon',
        },
        {
          title: 'Telegram',
          key: '__telegram',
          url: 'https://t.me/machinationsio',
          icon: '$commTelegramIcon',
        },
        {
          title: 'Twitter',
          key: '__twitter',
          url: 'https://www.x.com/machinationsio',
          icon: '$twitterSocialIcon',
        },

        {
          title: 'Youtube',
          key: '__youtube',
          url: 'http://www.youtube.com/channel/UCnui50w5BC_P7pfrF0XwoKg',
          icon: '$commYoutubeIcon',
        },
      ],
    }
  },
  methods: {
    switchDrawer() {
      this.$emit('switchDrawer')
    },
    isActiveRoute(type: string) {
      if (type === 'explore') {
        return this.$route.path.endsWith('/community/explore')
      }
      return this.$route.query.filter === type
    },
  },
})
