/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserType } from '@interfaces/UserTypes'
import { DesignersRepo, DesignerConnections } from '@repositories/DesignersRepo'
import { Context } from '@nuxt/types'
import {
  DesignerStoreMutations,
  HireDesignerRequest,
} from '~/domain/interfaces/DesignersTypes'

import { CARDS_PER_PAGE } from '~/domain/constants'
import {
  TRACKING_CATEGORY,
  TRACKING_TITLE,
  TRACKING_ACTION,
} from '@/domain/constants/TrackingConst'
import AppActions from '@/domain/constants/AppActions'
import { SegmentEventParams } from '~/domain/repositories/SegmentRepo'

export default {
  async followDesigner(
    { rootGetters, commit, dispatch }: any,
    {
      designer,
      isFollowed,
      context,
    }: {
      designer: UserType
      isFollowed: boolean
      context: Context
    }
  ) {
    if (!rootGetters.userIsAuthenticated) {
      commit(
        'dialogs/showDialog',
        {
          dialogName: 'loginDialog',
          show: true,
          sourceAction: 'follow this user',
        },
        { root: true }
      )
      return
    }
    await dispatch('trackSegmentFollowEvent', {
      isFollowed,
      designer,
      follower: rootGetters.authenticatedUser,
      context,
    })
    commit('setIsFollowedInList', {
      username: designer.username,
      isFollowed,
    })
    commit('setIsFollowed', {
      designer,
      isFollowed,
    })
  },

  async trackSegmentFollowEvent(
    { dispatch }: any,
    {
      isFollowed,
      designer,
      follower,
      context,
    }: {
      isFollowed: boolean
      designer: UserType
      follower: UserType
      context: Context
    }
  ): Promise<void> {
    let segmentData = {} as SegmentEventParams
    if (isFollowed) {
      segmentData = {
        callback: () => {},
        openInNewTab: false,
        name: TRACKING_TITLE.FOLLOW,
        action: TRACKING_ACTION.FOLLOW,
        category: TRACKING_CATEGORY.COMMUNITY,
        label: 'Web follow',
        misc: [
          { type: 'user', value: designer.username },
          { type: 'follower', value: follower.username },
        ],
        integrations: '',
      }
    } else {
      segmentData = {
        callback: () => {},
        openInNewTab: false,
        name: TRACKING_TITLE.UNFOLLOW,
        action: TRACKING_ACTION.UNFOLLOW,
        category: TRACKING_CATEGORY.COMMUNITY,
        label: 'Web unfollow',
        misc: [
          { type: 'user', value: designer.username },
          { type: 'follower', value: follower.username },
        ],
        integrations: '',
      }
    }
    await dispatch(
      AppActions.TRACK_SEGMENT_EVENT,
      {
        context,
        params: segmentData,
      },
      { root: true }
    )
  },

  async fetchDesigner(
    {},
    { context, username }: { context: Context; username: string }
  ) {
    const repoDesigners = new DesignersRepo(context)
    const designersResponse = await repoDesigners.fetchDesigners({
      usernames: username,
      isProfileView: true,
      excludePrivate: false,
      excludeEmptyProfiles: false,
    })
    return designersResponse?.list?.[0]
  },

  async fetchDesignersList(
    { commit, state },
    {
      context,
      page,
      type,
      authenticatedUser,
      count,
      doNotSetInStore,
      excludePopularTeams,
      getLastSeen,
      allUsersCount,
      filter,
    }: {
      context: Context
      page: number
      type: 'popular' | 'new'
      authenticatedUser?: string
      count?: number
      doNotSetInStore: boolean
      excludePopularTeams: boolean
      getLastSeen?: boolean
      allUsersCount?: boolean
      filter?: string
    }
  ) {
    commit(DesignerStoreMutations.IS_FETCHING, true)
    const repoDesigners = new DesignersRepo(context)
    const designersResponse = await repoDesigners.fetchDesignersSearch({
      count: count || CARDS_PER_PAGE,
      page,
      username: '',
      sortBy: type === 'popular' ? 'diagramLikeCount' : 'created',
      sortOrder: 'desc',
      excludeEmptyProfiles: true,
      authenticatedUser,
      excludePopularTeams:
        typeof excludePopularTeams !== 'undefined' ? excludePopularTeams : true, // default true
      getLastSeen,
      allUsersCount,
      filter,
    })

    if (!doNotSetInStore) {
      commit('updateDesignersList', designersResponse.list)
      commit('updateDesignersTotal', designersResponse.totalCount)
    }

    commit(DesignerStoreMutations.IS_FETCHING, false)

    return designersResponse
  },

  async fetchFeatured({ commit, state }, context: Context) {
    commit(DesignerStoreMutations.IS_FETCHING, true)
    if (!state.featuredLoaded) {
      const { $axios, $config } = context
      const designersRepo = new DesignersRepo(context)
      const communityFeatured = await $axios.$get(
        `${$config.nuxtBaseURL}/wp-json/community/featured`
      )
      const { usernames } = communityFeatured
      const designersOfTheMonthResponse = await designersRepo.fetchDesigners({
        usernames,
      })

      commit(
        'updateFeaturedList',
        designersOfTheMonthResponse.list.map((designer) => {
          const { email, ...designerData } = designer // Destructure to remove the 'email' field

          return {
            ...designerData,
            starred: true,
          }
        })
      )
      commit('setFeaturedLoaded', true)
    }

    commit(DesignerStoreMutations.IS_FETCHING, false)
  },

  async getDesignersOfTheMonth({}, context: Context) {
    const { $axios, $config } = context
    const designersRepo = new DesignersRepo(context)
    const communityFeatured = await $axios.$get(
      `${$config.nuxtBaseURL}/wp-json/community/featured`
    )
    const { usernames } = communityFeatured
    const designersOfTheMonthResponse = await designersRepo.fetchDesigners({
      usernames,
    })
    return designersOfTheMonthResponse
  },

  async fetchDesignersForHire(
    { commit, state },
    {
      context,
      count,
    }: {
      context: Context
      count: number
    }
  ) {
    if (!state.forHireLoaded) {
      const { $axios, $config } = context
      const designersRepo = new DesignersRepo(context)
      const designersForHireListResponse =
        await designersRepo.fetchDesignersSearch({
          filter: 'designersForHire',
          allUsersCount: false,
          count,
          page: 0,
          username: '',
          sortBy: 'diagramLikeCount',
          sortOrder: 'desc',
          excludeEmptyProfiles: true,
          excludePopularTeams: false,
        })

      commit(
        'updateForHireList',
        designersForHireListResponse.list.map((designer) => {
          const { email, ...designerData } = designer // Destructure to remove the 'email' field

          return {
            ...designerData,
            forHire: true,
          }
        })
      )
      commit('setForHireLoaded', true)
    }
  },

  async getUserConnections(
    {},
    { context, username }: { context: Context; username: string }
  ): Promise<DesignerConnections> {
    const designersRepo = new DesignersRepo(context)
    const response = await designersRepo.fetchConnections({
      username,
    })
    return response
  },

  async hireDesigner(
    {},
    { context, params }: { context: Context; params: HireDesignerRequest }
  ): Promise<void> {
    const designersRepo = new DesignersRepo(context)
    const response = await designersRepo.hireDesigner(params)
  },

  async searchDesigner(
    { commit, state },
    {
      context,
      params,
    }: {
      context: Context
      params: {
        searchText: string
        page: number
        count: number
        filter: string
      }
    }
  ): Promise<{ users: UserType[]; total: number }> {
    commit(DesignerStoreMutations.IS_FETCHING, true)
    const designerRepo = new DesignersRepo(context)
    const response = await designerRepo.searchDesigner(params)
    commit(DesignerStoreMutations.IS_FETCHING, false)
    return response
  },
}
