import { DesignersStoreState } from '@interfaces/DesignersTypes'

const designerState: DesignersStoreState = {
  featured: [],
  forHire: [],
  designersList: [],
  total: 0,
  featuredLoaded: false,
  forHireLoaded: false,
  fetching: false
}

export default () => designerState
