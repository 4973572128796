export const COOKIE_DOMAIN = 'machinations.io'

export const SOCIAL_PROVIDERS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  DISCORD: 'discord',
}

export const DESIGNER_VIEW_TABS = {
  1: 'newest',
  2: 'popular',
  3: 'designers-of-the-month',
} as { [key: number]: string }

export const DIAGRAM_VIEW_TABS = {
  1: 'newest',
  2: 'popular',
  3: 'diagrams-of-the-month',
} as { [key: number]: string }

export const PROFILE_AVATAR_SIZE = 124

export const URL_ARTICLE = '/articles'

export const CARDS_PER_PAGE = 24
export const HIGHLIGHTED_DIAGRAMS_COUNT = 8
export const HIGHLIGHTED_DESIGNERS_COUNT = 4
export const EXPLORE_DIAGRAMS_COUNT = 24
export const EXPLORE_DESIGNERS_COUNT = 24
export const FOR_HIRE_DESIGNERS_COUNT = 12

export const RELATED_DIAGRAMS_COUNT = 4
