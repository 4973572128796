import {
  DiagramTypes,
  Diagram,
  DiagramStoreMutations,
  DiagramAction,
  Tag,
} from '@interfaces/DiagramTypes'

import { DiagramApiResponse, DiagramRepo } from '@repositories/DiagramRepo'
import { Context } from '@nuxt/types'
import { convertDiagramTypeIntoSortByParam } from '@helpers/helpers'
import {
  CARDS_PER_PAGE,
  RELATED_DIAGRAMS_COUNT,
  DIAGRAM_ACTIONS,
  TRACKING_CATEGORY,
  TRACKING_TITLE,
  TRACKING_ACTION,
} from '~/domain/constants'
import AppActions from '@/domain/constants/AppActions'

import { SegmentEventParams } from '~/domain/repositories/SegmentRepo'
import { instanceOfDiagram } from '~/domain/helpers/CommunityHelper'
import { Template } from '~/domain/interfaces/TemplateTypes'

export default {
  async getDiagramsByFileKey({ state, commit }, { context, params }) {
    const repo: DiagramRepo = new DiagramRepo(context)
    const response: DiagramApiResponse = await repo.fetchDiagrams({
      fileKeys: [params?.fileKeys],
      authenticatedUser: params?.authenticatedUser,
      diagramView: params?.diagramView,
    })
    return response.list
  },

  async getGlossaryDiagrams({ state, commit, dispatch }, { context, params }) {
    const glossaryDiagrams: Diagram[] = await dispatch('getDiagramsByFileKey', {
      context,
      params,
    })

    commit(DiagramStoreMutations.SET_GLOSSARY, glossaryDiagrams)
  },

  async getDiagramsForUser(
    { state, commit },
    {
      context,
      username,
      likedDiagrams,
      count,
      page,
    }: {
      context: Context
      username: string
      likedDiagrams?: false
      getAllDiagrams?: false
      count?: null
      page?: number
    }
  ) {
    const repo: DiagramRepo = new DiagramRepo(context)

    const response: DiagramApiResponse = await repo.fetchDiagrams({
      username,
      getLikedDiagrams: likedDiagrams,
      count,
      page,
    })

    return response
  },

  async getDiagrams(
    { state, commit },
    {
      context,
      params,
      doNotSetInStore,
    }: {
      context: Context
      params: {
        diagramPage: number
        diagramType: DiagramTypes
        authenticatedUser?: string
        count?: number
        source?: string
      }
      doNotSetInStore: boolean
    }
  ) {
    commit(DiagramStoreMutations.IS_FETCHING, true)
    const repo: DiagramRepo = new DiagramRepo(context)
    const sortByParam = convertDiagramTypeIntoSortByParam(params.diagramType)

    const response: DiagramApiResponse = await repo.fetchDiagrams({
      count: params.count || CARDS_PER_PAGE,
      page: params.diagramPage,
      sortBy: sortByParam,
      sortOrder: 'desc',
      authenticatedUser: params.authenticatedUser,
      source: params.source,
    })
    if (!doNotSetInStore) {
      commit(
        params.diagramType === DiagramTypes.NEWEST
          ? DiagramStoreMutations.SET_NEWEST
          : DiagramStoreMutations.SET_POPULAR,
        { diagrams: response.list, total: response.totalCount }
      )
      commit(DiagramStoreMutations.SET_TOTAL, response.totalCount)
    }

    commit(DiagramStoreMutations.IS_FETCHING, false)
    return doNotSetInStore ? response : response.list
  },

  async getDiagramsOfTheMonth({ state, commit }, context: Context) {
    if (state.highlightedLoaded) {
      return state.highlighted
    }
    commit(DiagramStoreMutations.IS_FETCHING, true)
    const { $axios, $config } = context
    const repo: DiagramRepo = new DiagramRepo(context)
    const communityFeatured = await $axios.$get(
      `${$config.nuxtBaseURL}/wp-json/community/featured`
    )

    const response: DiagramApiResponse = await repo.fetchDiagrams({
      fileKeys: communityFeatured?.fileKeys.split(','),
    })
    if (response) {
      commit(DiagramStoreMutations.SET_HIGHLIGHTED, response.list)
      commit(DiagramStoreMutations.SET_HIGHLIGHTED_LOADED, true)
      commit(DiagramStoreMutations.IS_FETCHING, false)
      return response.list
    } else return []
  },

  async searchDiagrams(
    { commit, state },
    {
      context,
      params,
    }: {
      context: Context
      params: { searchText: string; page: number; count: number }
    }
  ): Promise<{ users: Diagram[]; total: number }> {
    commit(DiagramStoreMutations.IS_FETCHING, true)
    const diagramsRepo = new DiagramRepo(context)
    const response = await diagramsRepo.searchDiagrams(params)
    commit(DiagramStoreMutations.IS_FETCHING, false)
    return response
  },

  /**
   * Get current diagram details for diagram view.
   */
  async getDiagramCardDetails(
    { commit, dispatch },
    {
      context,
      params,
    }: {
      context: Context
      params: { fileKey: string; source?: string; additionalInfo?: boolean }
    }
  ): Promise<any> {
    try {
      const repo: DiagramRepo = new DiagramRepo(context)
      const response: DiagramApiResponse = await repo.fetchDiagrams({
        fileKeys: [params.fileKey],
        source: params.source,
        diagramView: true,
        additionalInfo: params.additionalInfo || false,
      })

      const diagramResponse = response?.list?.[0]
      let relatedDiagramsList

      if (diagramResponse) {
        commit('setCurrentDiagram', diagramResponse)
        commit('setRelatedDiagrams', null)

        // Get related diagrams.
        const relatedDiagrams: DiagramApiResponse = await repo.fetchDiagrams({
          count: RELATED_DIAGRAMS_COUNT,
          username: diagramResponse.owner?.username,
          currentDiagramKey: diagramResponse.fuid,
        })
        if (
          typeof relatedDiagrams?.list !== 'undefined' &&
          relatedDiagrams.list.length > 0
        ) {
          commit('setRelatedDiagrams', relatedDiagrams.list)
          relatedDiagramsList = relatedDiagrams.list
        } else {
          // Get "top trending diagrams".
          const highlighted = await dispatch('getDiagramsOfTheMonth', context)
          relatedDiagramsList = highlighted
        }
      }

      return { diagram: diagramResponse, relatedDiagrams: relatedDiagramsList }
    } catch (error) {
      // TODO: log error
    }
    return null
  },

  /**
   * Get data for current diagram WITHOUT CACHE
   */
  async getDiagramCardDataNoCache(
    { commit, dispatch },
    {
      context,
      params,
    }: {
      context: Context
      params: { fileKey: string; source?: string; additionalInfo?: boolean }
    }
  ): Promise<Diagram | null> {
    try {
      const repo: DiagramRepo = new DiagramRepo(context)
      const diagramsDataResponse: DiagramApiResponse =
        await repo.fetchDiagramsData({
          fileKeys: [params.fileKey],
          source: params.source,
          diagramView: true,
        })

      const diagramData = diagramsDataResponse?.list?.[0]

      commit('setIsLiked', diagramData?.likedByAuthenticatedUser)
      commit('setIsFollowed', diagramData?.owner?.isFollowed)
      diagramData?.likeCount !== undefined &&
        commit('setLikeCount', diagramData?.likeCount)
    } catch (error) {
      // TODO: log error
    }
    return null
  },

  /**
   * Get current diagram contributors for diagram view.
   */
  async getDiagramCardContributors(
    { commit, dispatch },
    {
      context,
      params,
    }: {
      context: Context
      params: { fileKey: string; ownerUsername?: string }
    }
  ): Promise<any> {
    try {
      const repo: DiagramRepo = new DiagramRepo(context)
      const response = (await repo.fetchDiagramContributors({
        fileKey: [params.fileKey],
        ownerUsername: params.ownerUsername,
      })) as any

      if (response?.diagramContributors.length > 0) {
        commit('setContributors', response?.diagramContributors)
      }
    } catch (error) {
      // TODO: log error
    }
    return null
  },

  async likeDiagram(
    // eslint-disable-next-line
    { rootGetters, commit, dispatch }: any,
    {
      action,
      diagram,
      context,
    }: {
      action: string
      diagram: Diagram | Template
      context: Context
    }
  ) {
    if (!rootGetters.userIsAuthenticated) {
      commit(
        'dialogs/showDialog',
        {
          dialogName: 'loginDialog',
          show: true,
          sourceAction: 'like this diagram',
        },
        { root: true }
      )
      return
    }
    await dispatch('trackSegmentLikeEvent', {
      action,
      diagram,
      context,
    })
  },

  async trackSegmentLikeEvent(
    { rootGetters, commit, dispatch }: any,
    {
      action,
      diagram,
      context,
    }: {
      action: string
      diagram: Diagram | Template
      context: Context
    }
  ) {
    const isDiagramInstance = instanceOfDiagram(diagram)
    commit('setDiagramAction', {
      type: action,
      fuid: isDiagramInstance
        ? (diagram as Diagram).fuid
        : (diagram as Template).ID.toString(),
    })
    const data = {
      misc: [
        {
          type: 'diagramName',
          value: isDiagramInstance
            ? (diagram as Diagram).name
            : (diagram as Template).title,
        },
        {
          type: 'diagramFuid',
          value: isDiagramInstance
            ? (diagram as Diagram).fuid
            : (diagram as Template).ID.toString(),
        },
        {
          type: 'username',
          value: rootGetters.authenticatedUser
            ? rootGetters.authenticatedUser.username
            : '',
        },
        { type: 'location', value: 'community' },
      ],
    } as SegmentEventParams
    if (action === DIAGRAM_ACTIONS.like) {
      data.name = TRACKING_TITLE.LIKE
      data.category = TRACKING_CATEGORY.COMMUNITY
      data.action = TRACKING_ACTION.LIKE
      data.label = 'Web like'
    } else {
      data.name = TRACKING_TITLE.UNLIKE
      data.category = TRACKING_CATEGORY.COMMUNITY
      data.action = TRACKING_ACTION.UNLIKE
      data.label = 'Web unlike'
    }
    await dispatch(
      AppActions.TRACK_SEGMENT_EVENT,
      {
        context,
        params: data,
      },
      { root: true }
    )
  },

  updateLikesForCurrentDiagram(
    { state, commit }: any,
    { action }: { action: DiagramAction }
  ): void {
    commit('updateDiagramLikes', {
      action,
      diagram: state.currentDiagram,
    })
  },
  async getNewTemplates(
    { state, commit }: any,
    { context }: { context: Context }
  ) {
    const repo: DiagramRepo = new DiagramRepo(context)
    const templates = await repo.fetchNewTemplates()
    commit('setNewTemplates', templates.list)
  },

  async getDiagramsByTag(
    { state, commit }: any,
    { context, tag }: { context: Context; tag: Tag }
  ) {
    const repo: DiagramRepo = new DiagramRepo(context)
    const diagramsByTags = await repo.fetchDiagramsByTags(tag)
    return diagramsByTags
  },
}
