var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header-item-container"},[_c('div',{staticClass:"header-item-container__items d-flex flex-wrap",class:!_vm.menuItems[0].children
        ? 'flex-column'
        : 'flex-row header-item-container__items__divider'},_vm._l((_vm.menuItems),function(menuItem){return _c(!menuItem.children ? 'nuxt-link' : 'div',{directives:[{name:"show",rawName:"v-show",value:(!menuItem.highlighted),expression:"!menuItem.highlighted"}],key:menuItem.name + menuItem.id.toString(),tag:"component",class:[
        { 'current-menu': _vm.isActiveRoute(_vm.$route.path, menuItem) },
        menuItem.children
          ? 'd-flex flex-column header-item-container__items__menu-item__container'
          : 'header-item-container__items__menu-item',
      ],attrs:{"to":menuItem.slug},on:{"mouseover":function($event){_vm.hoveredSubItemId = menuItem.id}}},[_c('span',{staticClass:"header-item-container__items__menu-item__text"},[_vm._v(_vm._s(menuItem.name))]),_vm._v(" "),_vm._l((menuItem.children),function(menuChildren){return (menuItem.children)?_c('nuxt-link',{key:menuChildren.name,staticClass:"header-item-container__items__menu-item",class:{
          'current-menu': _vm.isActiveRoute(_vm.$route.path, menuChildren),
        },attrs:{"to":_vm.getAbsolutePath(menuChildren.slug)},on:{"mouseover":function($event){_vm.hoveredSubItemId = menuChildren.id}}},[_c('span',{staticClass:"header-item-container__items__menu-item__text"},[_vm._v("\n          "+_vm._s(menuChildren.title)+"\n        ")])]):_vm._e()}),_vm._v(" "),(menuItem.isNew)?_c('v-chip',{staticClass:"m-chip ml-2"},[_vm._v(_vm._s(_vm.$t('wordNew')))]):_vm._e()],2)}),1),_vm._v(" "),(_vm.menuItems.some((item) => item.highlighted))?_c('div',{staticClass:"handbook__container"},[_c('nuxt-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":_vm.menuItems.find((item) => item.highlighted)?.slug}},[_c('div',{staticClass:"handbook",class:[_vm.menuItems.length < 5 ? 'pl-5 pt-3' : 'pa-5']},[_c('div',{staticClass:"liveops-whitepaper__title--menu"},[_vm._v("\n          "+_vm._s(_vm.$t('downloadThe'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"liveops-whitepaper__subtitle--menu"},[_vm._v("\n          "+_vm._s(_vm.$t('liveopsHandbookDescription'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"liveops-whitepaper__image-container"},[_c('nuxt-img',{staticClass:"liveops-whitepaper__frame-cover--menu",class:[_vm.menuItems.length < 6 ? 'small' : 'big'],attrs:{"src":"/images/liveops-whitepaper-photo.png","alt":"liveops-whitepaper-photo","width":"180"}}),_vm._v(" "),_c('div',{staticClass:"liveops-whitepaper__download-text"},[_vm._v("\n            "+_vm._s(_vm.$t('downloadForFree'))+"\n          ")])],1)])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }