import { UserType } from '@interfaces/UserTypes'
import { HireDesignerRequest } from './../interfaces/DesignersTypes'
import { BaseRepo } from './BaseRepo'

export interface DesignerApiResponse {
  totalCount: number
  list: UserType[]
}

export interface DesignerConnections {
  following: []
  followers: []
}

export class DesignersRepo extends BaseRepo {
  async fetchDesignersSearch(params: any = {}): Promise<DesignerApiResponse> {
    const { $axios } = this.nuxtContext

    let routeDesigners = `${this.apiBaseURL}/community/user-search/`
    const queryString = this.prepareQueryStringFrom(params)

    if (queryString !== '') {
      routeDesigners += `?${queryString}`
    }

    this.prepareHeaders($axios)

    const result = await $axios.$get(routeDesigners)
    const response = result.content

    return response
  }

  async fetchDesigners(params: any = {}): Promise<DesignerApiResponse> {
    const { $axios } = this.nuxtContext

    let routeDesigners = `${this.apiBaseURL}/community/users/`
    const queryString = this.prepareQueryStringFrom(params)

    if (queryString !== '') {
      routeDesigners += `?${queryString}`
    }

    this.prepareHeaders($axios)

    const result = await $axios.$get(routeDesigners)
    const response = result.content

    return response
  }

  async fetchConnections(params: any = {}): Promise<DesignerConnections> {
    const { $axios } = this.nuxtContext

    let route = `${this.apiBaseURL}/community/user/connections/`
    const queryString = this.prepareQueryStringFrom(params)

    if (queryString !== '') {
      route += `?${queryString}`
    }

    this.prepareHeaders($axios)

    const result = await $axios.$get(route)
    const response: DesignerConnections = {
      following: result.content.following,
      followers: result.content.followers,
    }

    return response
  }

  async hireDesigner(params: HireDesignerRequest): Promise<any> {
    try {
      const { $axios } = this.nuxtContext

      const route = `${this.apiBaseURL}/community/user/hire`

      const body = params
      const result = await $axios.$post(route, body)
      if (result && result.success === false) {
        throw new Error(`Hire designer error: ${result.content}`)
      }

      return result
    } catch (error: any) {
      if (error) {
        throw new Error(`Hire designer error: ${error.message}`)
      }
    }
  }

  async searchDesigner(params: {
    searchText: string
    page: number
    count: number
    filter: string
    authenticatedUser?: string
  }): Promise<any> {
    try {
      const { $axios } = this.nuxtContext
      let route = `${this.apiBaseURL}/community/user-search`
      const queryString = this.prepareQueryStringFrom({
        query: params.searchText,
        excludeEmptyProfiles: true,
        page: params.page,
        count: params.count,
        authenticatedUser: params.authenticatedUser,
        filter: params.filter,
      })
      if (queryString !== '') {
        route += `?${queryString}`
      }
      this.prepareHeaders($axios)
      const result = await $axios.$get(route)
      return {
        users: result.content.list,
        total: result.content.totalCount,
      }
    } catch (error: any) {
      console.error('ERROR: ', error)
      // if (error) throw new Error(`Search designer error: ${error.message}`)
    }
  }
}
